import { Button, Flex, toast } from "@appsmith/ads";
import React, { Suspense, lazy, Component } from "react";
import { type EmbeddingGenerators, type Integration } from "carbon-connect";
import ErrorBoundary from "components/editorComponents/ErrorBoundry";
import AppsmithLogo from "assets/images/appsmith_logo_square.png";
import type { ApiResponseError } from "api/types";
import { fetchAccessToken, fetchIntegrations } from "./RagApiRequests";

import "./carbon-modal.css";

const CarbonConnect = lazy(async () => import("./CarbonConnect"));

export interface CarbonButtonProps {
  datasourceId: string;
  workspaceId: string;
  onModalStateChange?: (value: boolean) => void;
}

const loadingError = <div>Failed to load CarbonConnect</div>;

const fallbackButton = (
  <Button isLoading size="md" startIcon="add-more">
    Add data
  </Button>
);

interface CarbonButtonState {
  isIntegrationsLoading: boolean;
  isCarbonModalOpen: boolean;
  integrations: Integration[];
  embeddingModel?: keyof typeof EmbeddingGenerators;
}

class _CarbonButton extends Component<CarbonButtonProps, CarbonButtonState> {
  state = {
    isIntegrationsLoading: false,
    isCarbonModalOpen: false,
    integrations: [],
    embeddingModel: undefined,
  };

  private onCarbonModalOpen = async () => {
    const { datasourceId, onModalStateChange, workspaceId } = this.props;

    // If integrations has already been loaded, then just open CarbonModal
    if (this.state.integrations.length !== 0) {
      this.setState({
        isCarbonModalOpen: true,
      });

      onModalStateChange && onModalStateChange(true);

      return;
    }

    this.setState({
      isIntegrationsLoading: true,
    });

    try {
      const { embeddingModel, integrations } = await fetchIntegrations(
        datasourceId,
        workspaceId,
      );

      this.setState({
        isIntegrationsLoading: false,
        integrations,
        embeddingModel,
        isCarbonModalOpen: true,
      });

      onModalStateChange && onModalStateChange(true);
    } catch (error) {
      toast.show((error as ApiResponseError).message, { kind: "error" });
      this.setState({
        isIntegrationsLoading: false,
      });
    }
  };

  render() {
    const { datasourceId, onModalStateChange, workspaceId } = this.props;

    return (
      <ErrorBoundary fallback={loadingError}>
        <Suspense fallback={fallbackButton}>
          <Flex alignItems="center" gap="spaces-3">
            <Button
              isLoading={this.state.isIntegrationsLoading}
              kind="secondary"
              onClick={this.onCarbonModalOpen}
              size="md"
            >
              Import data
            </Button>
            Upload files, connect your Google Drive, Jira etc.
          </Flex>
          {this.state.isCarbonModalOpen && (
            <CarbonConnect
              backButtonText="Close"
              brandIcon={AppsmithLogo}
              embeddingModel={this.state.embeddingModel}
              enabledIntegrations={this.state.integrations}
              open={this.state.isCarbonModalOpen}
              orgName="Appsmith"
              setOpen={(isOpen) => {
                this.setState({ isCarbonModalOpen: isOpen as boolean });

                if (onModalStateChange) {
                  onModalStateChange(isOpen as boolean);
                }
              }}
              tokenFetcher={async () =>
                fetchAccessToken(datasourceId, workspaceId)
              }
            />
          )}
        </Suspense>
      </ErrorBoundary>
    );
  }
}

export const CarbonButton = _CarbonButton;
