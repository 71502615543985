import { Flex, Spinner } from "@appsmith/wds";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { ChatMessageItem } from "./ChatMessageItem";
import styles from "./styles.module.css";
import type { Message, MessageCitation } from "./types";
import { useDebounceCallback } from "usehooks-ts";

// If the scroll position is within this gap from the bottom, we consider the list at the bottom
// and don't disable auto scroll
const AUTO_SCROLL_THRESHOLD = 5;

interface ChatThreadProps {
  isThreadLoading?: boolean;
  onApplyAssistantSuggestion?: (suggestion: string) => void;
  onOpenCitation: (messageId: string, citation: MessageCitation) => void;
  thread: Message[];
}

export const ChatMessageList = (props: ChatThreadProps) => {
  const {
    isThreadLoading,
    onApplyAssistantSuggestion,
    onOpenCitation,
    thread,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const lastScrollTop = useRef(0);

  const getContainerScrollBottom = (container: HTMLDivElement): number => {
    return (
      container.scrollHeight - container.scrollTop - container.clientHeight
    );
  };

  // Auto scroll is enabled when the list is at the bottom
  const toggleAutoScroll = (container: HTMLDivElement): void => {
    // Scrolls down
    if (container.scrollTop > lastScrollTop.current) {
      // When scrollBottom is 0, it means that the user is at the bottom of the list
      if (getContainerScrollBottom(container) <= AUTO_SCROLL_THRESHOLD) {
        setIsAutoScrollEnabled(true);
      }
    }
    // Scrolls up
    else if (container.scrollTop < lastScrollTop.current) {
      if (getContainerScrollBottom(container) > AUTO_SCROLL_THRESHOLD) {
        setIsAutoScrollEnabled(false);
      }
    }
  };

  const handleScroll = useCallback(() => {
    const container = containerRef.current;

    if (!container) return;

    toggleAutoScroll(container);

    lastScrollTop.current = container.scrollTop;
  }, [isAutoScrollEnabled]);

  const debouncedHandleScroll = useDebounceCallback(handleScroll, 50, {
    leading: true,
    trailing: true,
  });

  useEffect(
    function scrollToEndOnNewMessages() {
      const container = containerRef.current;

      if (!container || !isAutoScrollEnabled) return;

      debouncedHandleScroll.cancel();
      scrollerRef.current?.scrollIntoView({ behavior: "instant" });
    },
    [thread, isAutoScrollEnabled, debouncedHandleScroll],
  );

  if (Boolean(isThreadLoading)) {
    return (
      <Flex
        alignItems="center"
        flexGrow={1}
        isInner
        justifyContent="center"
        padding="spacing-5"
      >
        <Spinner />
      </Flex>
    );
  }

  return (
    <div
      className={styles.messageList}
      onScroll={debouncedHandleScroll}
      ref={containerRef}
    >
      <Flex direction="column" flexGrow={1} gap="spacing-6">
        {thread.map((message: Message) => (
          <ChatMessageItem
            isAssistantSuggestionVisible={thread.length === 1}
            key={message.id}
            message={message}
            onApplyAssistantSuggestion={onApplyAssistantSuggestion}
            onOpenCitation={onOpenCitation}
          />
        ))}
      </Flex>

      <div ref={scrollerRef} />
    </div>
  );
};
