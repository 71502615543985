import type { MessageCitationDto } from "./widget/dtos/messageCitationDto";

const apiURL = `/api/v1/ai/invoke`;

interface ThreadRunCreatedStreamLine {
  event: "thread.run.created"; // discriminator
  data: {
    threadId: string;
  };
}

interface ThreadMessageCreatedStreamLine {
  event: "thread.message.created"; // discriminator
  data: {
    id: string;
    createdAt: number;
    role: "user" | "assistant";
    delta: {
      content: Array<{
        text: {
          value: string;
        };
        type: "text";
      }>;
    };
  };
}

interface ThreadMessageDeltaStreamLine {
  event: "thread.message.delta"; // discriminator
  data: {
    delta: {
      content: Array<{
        text: {
          value: string;
        };
        type: "text";
      }>;
    };
  };
}

interface ThreadCitationsContentStreamLine {
  event: "citations.content"; // discriminator
  data: {
    citations: Array<MessageCitationDto>;
  };
}

interface ThreadCitationsRefStreamLine {
  event: "citations.ref"; // discriminator
  data: {
    refs: string;
  };
}

export type AiAssistantStreamLine =
  | ThreadRunCreatedStreamLine
  | ThreadMessageCreatedStreamLine
  | ThreadMessageDeltaStreamLine
  | ThreadCitationsContentStreamLine
  | ThreadCitationsRefStreamLine;

interface SendMessageParams {
  threadId: string;
  actionId: string;
  widgetId: string;
  message: string;
  activeEnv: string;
}

export const sendMessage = async (
  params: SendMessageParams,
): Promise<Response> => {
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-Requested-By": "Appsmith",
    Accept: "text/event-stream",
    "X-Appsmith-EnvironmentId": params.activeEnv,
  });

  const requestBody = {
    requestType: "SEND_MESSAGE_STREAM",
    config: {
      threadId: params.threadId,
      userName: "",
      resourceId: params.widgetId,
      actionId: params.actionId,
    },
    message: params.message,
    viewMode: false,
  };

  const request = new Request(apiURL, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(requestBody),
  });

  return fetch(request);
};
