import React, { useState } from "react";
import { default as CE_usePluginActionResponseTabs } from "ce/PluginActionEditor/components/PluginActionResponse/hooks/usePluginActionResponseTabs";
import { PluginType } from "entities/Plugin";
import { usePluginActionContext } from "PluginActionEditor";
import { useSelector } from "react-redux";
import { getIsAnvilEnabledInCurrentApplication } from "layoutSystems/anvil/integrations/selectors";
import { DEBUGGER_TAB_KEYS } from "components/editorComponents/Debugger/constants";
import { RagDocuments } from "ee/components/formControls/RagDocuments";
import type { Datasource } from "entities/Datasource";

function usePluginActionResponseTabs() {
  const [ragDocumentsCount, setRagDocumentsCount] = useState<number>();
  const { datasource, plugin } = usePluginActionContext();
  const isAnvilEnabled = useSelector(getIsAnvilEnabledInCurrentApplication);
  const tabs = CE_usePluginActionResponseTabs();

  if ([PluginType.AI].includes(plugin.type) && isAnvilEnabled) {
    // for anvil, we delete DATASOURCE_TAB
    const newTabs = tabs.filter(
      (tab) => !(tab.key === DEBUGGER_TAB_KEYS.DATASOURCE_TAB),
    );

    // add DATA_TAB for anvil to the first position
    return [
      {
        key: DEBUGGER_TAB_KEYS.DATA_TAB,
        title: "Data",
        count: ragDocumentsCount,
        panelComponent: (
          <RagDocuments
            datasourceId={(datasource as Datasource)?.id}
            isDeletedAvailable={false}
            setCount={setRagDocumentsCount}
            workspaceId={datasource?.workspaceId}
          />
        ),
      },
      ...newTabs,
    ];
  }

  return CE_usePluginActionResponseTabs();
}

export default usePluginActionResponseTabs;
